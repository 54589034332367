import moment    from 'moment';
import {isCluji} from './auth';
import store     from '@/store';

export const momentDates = data => {
    if (Array.isArray(data)) return data.map(momentDates);

    let dto = {
        ...data,
        dateRapport: moment(data.dateRapport.date)
    };

    if (data.dateOrigine && data.dateOrigine.date) dto.dateOrigine = moment(data.dateOrigine.date);

    return dto;
};

export const canEdit = (citation,user = store.state.user.utilisateur) =>
    isCluji() && (citation.auteur && citation.auteur.id === user.id || citation.rapporteur.id === user.id);

export default {momentDates, canEdit};