<template>
    <cluji-card subtitle="Phrases prononcées par d'illustres Clujistes, actuels ou passés">
        <template #title>
            Citations
            <b-button pill variant="success" size="sm" class="ml-2" v-b-tooltip.hover="'Nouvelle citation'"
                      @click="createCitation">
                <font-awesome-icon icon="plus"/>
            </b-button>
        </template>

        <citation v-for="citation in citations" :citation="citation" :key="'citation_'+citation.id" @edit="loadData"/>

        <b-pagination v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="pageSize"
                      align="center"/>
    </cluji-card>
</template>

<script>
    import alert         from '@/util/alert';
    import {apiPath}     from '@/util/http';
    import {momentDates} from '@/util/citation';

    const Citation          = () => import("@/components/Citation");
    const ClujiCard         = () => import("@/components/ClujiCard");
    const EditCitationModal = () => import("@/components/modals/EditCitationModal");

    export default {
        name: "Citations",
        components: {Citation, ClujiCard},
        data: () => ({
            citations: [],
            totalItems: 0,
            pageSize: 999,
            currentPage: 1
        }),
        methods: {
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_citations', {page: this.currentPage}))
                    .then(response => {
                        this.citations  = momentDates(response.data.data);
                        this.totalItems = response.data.totalItems;
                        this.pageSize   = response.data.pageSize;
                    })
                    .catch(() => this.$toaster.error('Impossible de charger les citations'))
                    .finally(alert.stopLoading);
            },
            createCitation() {
                this.$store.dispatch('modal/create', {
                    component: EditCitationModal,
                    props: {callback: () => this.loadData()}
                });
            },
        },
        watch: {
            currentPage(val) {
                this.currentPage = val;
                this.loadData();
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
